<template>
	<el-button type="info" style="font-family: 'UKIJ Tuz Tom';font-size: 18px;" @click="onCancel">
		<!-- {{yuyan.fanhui}} -->↩
	</el-button>
	<div style="padding: 20px;height: 70vh;width: 70vw;margin: 0 auto;">
		<div>
			<el-row style="border: #5555ff 1px solid;">
						<el-col :span="8">
							<el-card style="border: rgba(1, 1, 1, 0) 1px solid;" shadow="never" class="box-card">
								<template #header>
									<div class="card-header">
										<span>注意事项</span>
									</div>
								</template>
								<div style="line-height: 50px;">
								<pre style="color: #5555ff;font-weight: 900;font-size: 18px;">1、遵守考场纪律，服从监考人员指挥。
2、进入考场，手机、拷机关机。禁止抽烟
，禁止吃零食。
3、未经工作人员允许，考生禁上随意出入
考场
4、考场内禁止大声喧哗，任意走动。
5、考试中认真答题，不准交头耳。
6、考试中不准冒名顶替，不乘虚作假。
7、注意考场卫生，禁止随地吐大止乱扔纸
屑。
8、爱护公物及考试设备。
								</pre>
								</div>
							</el-card>
						</el-col>
						<el-col :span="16">
							<el-card shadow="never">
								<div style="text-align: center;margin-top: 50px;">
									<div>
										Rolom驾考考试提示
									</div>
								</div>
								<div style="display: flex;justify-content: space-around;margin-top: 20px;font-size: 18px;">
									<div>
										<div style="text-align: left;color: red;">身份证号: 65655648946524686</div>
										<div style="text-align: left;color: red;margin-top: 10px;">考生姓名: 65655648946524686</div>
										<div style="text-align: left;color: red;margin-top: 10px;">点击[确认]按钮开始考试</div>
									</div>
									<div>
										<el-image style="width: 120px;" src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg3.doubanio.com%2Fview%2Fphoto%2Fm%2Fpublic%2Fp2215055670.jpg&refer=http%3A%2F%2Fimg3.doubanio.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1661171110&t=e03f864f38a1d5071d8ca6d4e59d5956" fit="contain">
										</el-image>
									</div>
								</div>
								
								<div style="text-align: right;margin: 200px 300px 0 0;">
									<el-button type="primary" style="font-family: 'UKIJ Tuz Tom';font-size: 18px;" @click="goExample">确 认</el-button>
								</div>
								<div style="margin-top: 200px;color: #5555ff;font-size: 18px;">
									操作提示：每题考试答案确定后，点击【下一题】，电脑立即判定所选
									答案，如选择错误，系统将提示正确答案，提示后不允许修改答索
								</div>
								
							</el-card>
						</el-col>
					</el-row>
		</div>
		
	</div>
</template>

<script>
	import {
		fetchDetail
	} from '@/api/exam'
	import {
		ElLoading
	} from 'element-plus'
	import {
		createPaper
	} from '@/api/exam'
	export default {
		data() {
			return {
				examId: '',
				detailData: {},
				type: ''
			}
		},
		created() {
			this.yuyan = this.$language()
			this.examId = this.$route.query.examId
			this.type = this.$route.query.type
			fetchDetail(this.examId).then(response => {
				this.detailData = response
			})
			console.log(this.type)
		},
		methods: {
			onCancel() {
				this.$router.push({
					name: '首页'
				})
			},
			goExample() {
				
				const that = this
				if (this.type == "kaoshi") {
					const loading = ElLoading.service({
						lock: true,
						text: '正在加载考试中...',
						background: 'rgba(0, 0, 0, 0.7)',
					})
					createPaper({
						examId: this.examId
					}).then(response => {
						loading.close()
						if (response.id) {
							this.$message({
								message: that.yuyan.preview_exam_start,
								type: 'success'
							})
							setTimeout(function() {
								// loading.close()
								that.dialogVisible = false
								that.$router.replace({
									path: '/start',
									query: {
										id: response.id,
										examId: this.examId
									}
								})
								console.log(this.examId)
							}, 1000)
						} else {
							this.$message({
								message: '创建失败请联系管理员！',
								type: 'error'
							})
						}
					}).catch(err =>{
						loading.close()
					})
				} else {
					this.$router.replace({
						path: '/practice',
						query: {
							examId: this.examId
						}
					})
				}

			}
		}
	}
</script>

<style>
	.name-sn {
		font-size: 24px;
		font-weight: 500;
		color: #262626;
	}

	.ul-sn {
		margin-top: 18px;
		line-height: 30px;
		text-align: left;
		list-style: none;
	}
</style>
